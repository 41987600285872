
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <!-- <form @submit.prevent="addCustomers" autocomplete="off">
                    <v-layout row wrap>
        
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-text-field v-model="customers.customer_username" type="text" :label="$store.getters.language.data.customers.customer_username" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-text-field v-model="customers.customer_phone" type="textarea" :label="$store.getters.language.data.customers.customer_phone" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-text-field v-model="customers.customer_phone_2" type="textarea" :label="$store.getters.language.data.customers.customer_phone_2" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex>
                        <v-flex xs12 lg4 xl4 md4 sm4>
                                <v-select class="mx-1" clearable  :items="['slemani','hawler','karkuk','dhok','zaxo','baghdad','darbandixan','halabja','rania','others']" v-model="customers.customer_city" dense  filled outlined  item-text="customer_city"
                                    item-value="customer_city" :return-object="false" :label="$store.getters.language.data.customers.customer_city">
                                </v-select>
                        </v-flex>
                
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-text-field v-model="customers.customer_address" type="text" :label="$store.getters.language.data.customers.customer_address" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                        <v-flex xs12 lg4 xl4 md4 sm4>
                                <v-select class="mx-1" clearable  :items="['elly_online_store','shein_store_iraq','pages']" v-model="customers.customer_type" dense  filled outlined  item-text="customer_type"
                                    item-value="customer_type" :return-object="false" :label="$store.getters.language.data.customers.customer_type">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg4 xl4 md4 sm4>
                                <v-select class="mx-1" clearable  :items="users" v-model="customers.user_id" dense  filled outlined  item-text="user_id"
                                    item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.customers.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form> -->
                <h1>Customers List :</h1>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headers"  v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="customer_id">
                    <template v-slot:[`item.customer_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/customers-list/'+item.customer_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectCustomers(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteCustomersList">{{$store.getters.language.data.customers.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.customers.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.customers.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteCustomers(selected_customers.customer_id)">
                {{$store.getters.language.data.customers.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/customers.request.js'
    export default {
        data() {
            return {
                customers: {},
                search: '',
                loading : true,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                selected_customers : {},
                delete_dialog: false,
                headers: [

                    
                        { 
                            text: this.$store.getters.language.data.customers.customer_username,
                            align: 'start',
                            sortable: true,
                            value: 'customer_username',
                        },
                        { 
                            text: this.$store.getters.language.data.customers.customer_phone,
                            align: 'start',
                            sortable: true,
                            value: 'customer_phone',
                        },
                        { 
                            text: this.$store.getters.language.data.customers.customer_phone_2,
                            align: 'start',
                            sortable: true,
                            value: 'customer_phone_2',
                        },
                        { 
                            text: this.$store.getters.language.data.customers.customer_city,
                            align: 'start',
                            sortable: true,
                            value: 'customer_city',
                        },
                        { 
                            text: this.$store.getters.language.data.customers.customer_address,
                            align: 'start',
                            sortable: true,
                            value: 'customer_address',
                        },
                        { 
                            text: this.$store.getters.language.data.customers.customer_type,
                            align: 'start',
                            sortable: true,
                            value: 'customer_type',
                        },
                        {
                              text: this.$store.getters.language.data.customers.customer_status,
                            align: 'start',
                            sortable: true,
                            value: 'customer_status',
                        },
                    {
                        text: this.$store.getters.language.data.users.user_username,
                        align: 'start',
                        sortable: true,
                        value: 'user_username',
                    },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: 'customer_id',
        }
                ],
            }
        },
        computed: {
            users(){
                return this.$store.getters.users_list
            },
        },
        mounted(){
            this.readCustomers();
        },
        methods: {
            addCustomers() {
                this.loading_btn = true
                requests.createCustomers(this.customers).then(r => {
                    if (r.status == 200) {
                        this.customers = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'Customers Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add Customers',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteCustomers(customer_id) {
                requests.deleteCustomers(customer_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.customer_id != customer_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'Customers Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteCustomersList() {
                let ids = this.selected_rows.map(m => m.customer_id)
                requests.deleteCustomersList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.customer_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.selected_rows.length+' Customers Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readCustomers() {
                this.loading = true
                requests.getAllCustomers().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.rows
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Customers',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read Customers',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectCustomers(i) {
                this.selected_customers = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    